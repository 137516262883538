import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BlogPostLink } from 'components/blog-post-link';
import { Button } from 'components/button-new';
import { CategoryBanner } from 'components/category-banner';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { array, object, string } from 'prop-types';

const SSection = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;

const SPostsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    ${CONSTANTS.MEDIA.max600`
    grid-template-columns:  1fr ;
`}
`;

const SButton = styled(Button)`
    width: max-content;
    align-self: center;
`;

export const Section = ({
    title,
    description,
    svg,
    posts,
    buttonLabel,
    moreUrl,
    id,
    hub,
}) => {
    return (
        <SSection id={id}>
            <CategoryBanner {...{ title, description, svg }} />
            <SPostsWrapper>
                {posts.map(({ node }) => (
                    <BlogPostLink
                        key={node.id}
                        blogPost={node}
                        withDescription={false}
                        isSmallerTitle={true}
                        isMain={false}
                        hub={hub}
                    />
                ))}
            </SPostsWrapper>
            <SButton variant="outline" component="gatsby-link" to={moreUrl}>
                <FormattedMessage id={buttonLabel} />
            </SButton>
        </SSection>
    );
};

Section.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    svg: object.isRequired,
    posts: array.isRequired,
    buttonLabel: string.isRequired,
    moreUrl: string.isRequired,
    id: string.isRequired,
    hub: string.isRequired,
};
